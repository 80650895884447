import React from 'react';
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsTemplate = ({data}) => {

  const pagedata = data.markdownRemark;
  const {title, subhead, date, author, tags} = pagedata.frontmatter;

  return (
    <Layout>
      <SEO title={title} />
      <article>
        <h1 className="news--title">{title}</h1>
        { subhead && <h2 classname="news--subhead">{ subhead }</h2>}
        <strong classname="news--dateline">{`News | ${date}${author ? ` by ${author}` : ''}`}</strong>
        <hr />
        <section
          className="news--content"
          dangerouslySetInnerHTML={{__html: pagedata.html}}
        />
      </article>
{ /*
      <section className="news--tags">
        <h3>Tags:</h3>
        <ul>
          {
            tags.map((tag, i)=> <li key={i}>{tag}</li>)
          }
        </ul>
      </section>
 */ }
    </Layout>
  );
};

export const query = graphql `query($slug: String!) {
    markdownRemark( fields: { slug: {eq: $slug} } ) {
      frontmatter {
        title
        date(formatString: "dddd, MMMM D, YYYY") # Sunday, August 23, 2020
        author
        tags
      }
      html
    }
  }
`;

export default NewsTemplate;
